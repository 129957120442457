
import Chart from 'primevue/chart'

export default {
  name: 'LineChart',
  components: {
    Chart
  },
  data() {
    const documentStyle = getComputedStyle(document.documentElement)
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: true,
            labels: {
              boxWidth: 16,
              boxHeight: 16,
              fontSize: 20,
              fontColor: 'black',
              padding: 20,
            },
            position: 'bottom',
            align: 'start'
          }
        },
        interaction: {
          mode: 'index',
          intersect: false,
        },
        tooltips: {
          mode: 'index',
          reverse: true,
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary
            },
            grid: {
              display: false
            }
          },
          y: {
            ticks: {
              color: textColorSecondary,
              stepSize: 10,
              suggestedMin: 0,
              suggestedMax: 100
            },
            grid: {
              display: false
            },
            min: 0,
            max: 50
          }
        }
        // Add more chart options as needed
      }
    }
  },
  props: {
    chartType: {
      type: String,
      default: 'line'
    },
    chartData: {
      type: Object,
      default: () => ({})
    },
    chartHeight: {
      type: Number,
      default: 200
    }
  }
}
