import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-w-min" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Chart, {
      type: $props.chartType,
      data: $props.chartData,
      options: $data.chartOptions,
      height: 500,
      width: 1440
    }, null, 8, ["type", "data", "options"])
  ]))
}