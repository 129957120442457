
import { ref, defineComponent } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from 'primevue/progressbar'
import ButtonPrimary from '@/views/components/button/ButtonPrimary.vue'
import ButtonSecondary from '@/views/components/button/ButtonSecondary.vue'
import ButtonUploadPrimary from '@/views/components/button/ButtonPrimaryUpload.vue'
import Modal from '@/views/components/modal/ModalDefault.vue'
import CardBlueBorder from '@/views/components/card/CustomBlueBorder.vue'
import Popper from 'vue3-popper'
import LineChart from '@/views/components/chart/LineChart.vue'
import currencyNumberFormat from '@/utils/helpers/rupiahFormat'
import moment from 'moment'
import ResiProductionDummy from '@/views/pages/transaction/resi/dummyData/production.json'
import ResiStagingDummy from '@/views/pages/transaction/resi/dummyData/staging.json'

export default defineComponent({
  name: 'Dashboard',
  components: {
    ButtonUploadPrimary,
    ButtonPrimary,
    ButtonSecondary,
    Popper,
    Modal,
    LineChart,
    ProgressBar,
    CardBlueBorder
  },
  data() {
    return {
      showSearchModal: false,
      dataNotFound: false,
      textSearch: '',
      selectedMonth: `${moment().format('MM')}`,
      currentYear: new Date().getFullYear(),
      months: [
        { name: 'Januari', value: '01' },
        { name: 'Febuari', value: '02' },
        { name: 'Maret', value: '03' },
        { name: 'April', value: '04' },
        { name: 'Mei', value: '05' },
        { name: 'Juni', value: '06' },
        { name: 'Juli', value: '07' },
        { name: 'Agustus', value: '08' },
        { name: 'September', value: '09' },
        { name: 'Oktober', value: '10' },
        { name: 'November', value: '11' },
        { name: 'Desember', value: '12' },
      ],
      defaultDatasets: {
        backgroundColor: 'white',
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 15,
        fill: false,
        lineTension: 0.5,
      },
      chartData: {
        labels: ['Minggu 1', 'Minggu 2', 'Minggu 3', 'Minggu 4'],
        datasets: [
          {
            label: 'Draft',
            data: [0, 0, 0, 0],
            borderColor: 'orange',
            backgroundColor: 'white',
            pointStyle: 'circle',
            pointRadius: 10,
            pointHoverRadius: 15,
            fill: false,
            lineTension: 0.5,
            ...this.defaultDatasets
          },
          {
            label: 'Resi',
            data: [0, 0, 0, 0],
            borderColor: 'blue',
            backgroundColor: 'white',
            pointStyle: 'circle',
            pointRadius: 10,
            pointHoverRadius: 15,
            fill: false,
            lineTension: 0.5,
          },
          {
            label: 'Paket Terkirim',
            data: [0, 0, 0, 0],
            borderColor: 'green',
            backgroundColor: 'white',
            pointStyle: 'circle',
            pointRadius: 10,
            pointHoverRadius: 15,
            fill: false,
            lineTension: 0.5,
          },
          {
            label: 'Transaksi Dibatalkan',
            data: [0, 0, 0, 0],
            borderColor: 'red',
            backgroundColor: 'white',
            pointStyle: 'circle',
            pointRadius: 10,
            pointHoverRadius: 15,
            fill: false,
            lineTension: 0.5,
          }
        ]
      }
    }
  },
  methods: {
    handleSearch(e: any) {
      this.showSearchModal = true
    },
    hideSearchModal() {
      this.showSearchModal = false
      this.dataNotFound = false
    },
    redirecToBillingDetail() {
      this.$router.push({
        name: 'terms-of-payment',
      })
    }
  },
  watch: {
    showSearchModal(val) {
      if (val) {
        setTimeout(() => {
          if (this.showSearchModal && !this.dataNotFound) {
            const resiProductionDummy = ref(ResiProductionDummy.data)
            const resiStagingDummy = ref(ResiStagingDummy.data)
            const env = localStorage.getItem('environment')
            const dataTemp = env === 'production' ? resiProductionDummy.value : resiStagingDummy.value
            const findResi = dataTemp.find((item: any) => item.resiNumber.toLowerCase().includes(this.textSearch.toLowerCase()))

            if (findResi) {
              this.showSearchModal = false
              this.$router.push({
                name: 'transaction-detail',
                params: { id: findResi?.resiNumber },
                query: { from: 'dashboard' }
              })
            } else {
              this.showSearchModal = false
              setTimeout(() => {
                this.dataNotFound = true
                this.showSearchModal = true
              }, 400)
            }
          }
        }, 1000)
      }
    },
  },
  setup() {
    const store = useStore()
    const tooltipText = 'Bayar tagihan Anda pada periode sebelumnya untuk bisa melanjutkan transaksi!'
    const transactions = [{
      name: 'Transaksi Bulan Ini',
      value: 0,
      icon: 'case.svg',
      stgIcon: 'case-secondary.svg'
    },
    {
      name: 'Nominal Transaksi Bulan Ini',
      value: 'Rp. 0',
      icon: 'dolar_currency.svg',
      stgIcon: 'dolar_currency_secondary.svg'
    },
    {
      name: 'Transaksi Berjalan Bulan Ini',
      value: 0,
      icon: 'truck_box.svg',
      stgIcon: 'truck_box_secondary.svg'
    },
    {
      name: 'Transaksi Selesai Bulan Ini',
      value: 0,
      icon: 'double_checklist.svg',
      stgIcon: 'double_checklist_secondary.svg'
    }]

    const topValue = {
      restLimit: 0,
      limit: 0,
      usage: 0
    }

    const doCurrencyNumberFormat = (val: any) => currencyNumberFormat(val)

    return {
      tooltipText,
      transactions,
      topValue,
      doCurrencyNumberFormat,
      store
    }
  }
})

